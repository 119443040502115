import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { Collapse } from "react-bootstrap"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"


export default function CategoryProducts({ data }) {
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)
  const [childCategory, setChildCategory] = useState(null)

  console.log("datas", data)
  function getEcwidCategories() {
    let parents = data.filter(el => !el.parentId)
    console.log(parents)
    if(parents.length > 0) {
      parents = parents.map(el => {
        const mychildren = data.filter(child => child.parentId === el.ecwid_id)
        if(mychildren.length) {
          return { ...el, subCategories: mychildren}
        }
        return {...el, subCategories: []}
      })
    }
    setCategories(parents)
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    getEcwidCategories()
  }, [])

  return (
    <>
      <ul className="list-group list-group-flush category-list">
        {categories.map(el => {
          let url = el.url.split("/").slice(-1)
          return (
          <li className="list-group-item" key={el.id}>
            <div
              style={{ cursor: "pointer" }}
              className={`d-flex justify-content-between align-items-center ${
                activeCategory?.id === el.id ? "text-primary" : ""
              }`}
              onClick={() => {
                setActiveCategory(prev =>
                  prev?.id === el.id ? null : el
                )
                setChildCategory(null)
              }}
            >
              <Link 
                to={`/${url}`}
              className="pr-2">{el.name}</Link>
              {el.subCategories.length > 0 && (
                <span>
                  {activeCategory?.id === el.id ? (
                    <VscTriangleDown />
                  ) : (
                    <VscTriangleUp />
                  )}
                </span>
              )}
            </div>

            <Collapse in={activeCategory?.id === el.id}>
              <ul
                className="list-group list-group-flush"
                id="example-collapse-text"
              >
                {el.subCategories.map(sub => {
                  let subUrl = sub.url.split("/").slice(-1)
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      className={`list-group-item ${
                        childCategory?.id === sub.id ? "text-primary" : ""
                      }`}
                      key={sub.id}
                    >
                      <Link to={`/${subUrl}`}>{sub.name}</Link>
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          </li>
        )
        })}
      </ul>
    </>

  )
}