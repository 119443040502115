import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { Collapse, Dropdown } from "react-bootstrap"
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc"
import CategoryProducts from "./productCategory"
import { BiDotsVerticalRounded } from "react-icons/bi";
import "./category.css"

export default function ProductCategory({ data }) {

  return (
    <>
      <div id="mobile-category" className="d-block d-sm-none">
        <Dropdown>
          <Dropdown.Toggle className="w-100 p-2" variant="outline-primary">
            Select a category
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <CategoryProducts data={data} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div id="desktop-category" className="d-none d-sm-block">
         <div className="category-heading">
          <h2>Categories</h2>
        </div>
        <CategoryProducts data={data} />
      </div>
    </>

  )
}